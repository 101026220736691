<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <div class="col-10">
        <textarea
            v-model="trackingNumbers"
            class="form-control"
            rows="15"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Process Batch Claim</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "OrderBatch",
  data: function () {
    return {
      trackingNumbers: "",
      breadcrumbs: [{ name: "Order", link: "/order" }, { name: "Order Batch Claim" }],
      submitted: false,
      alertContent: "",
      alertClass: "alert-danger"
    };
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      axios.post("/order/batch", { data : this.trackingNumbers } )
        .then(() => {
          this.alertContent = "Orders have been claimed successfully!";
          this.alertClass = "alert-success";
          this.submitted = false;
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
          this.submitted = false;
        });
    },
  },
};
</script>
